import { Success, Created } from 'src/common';
import type {
  Error,
  CreateResponse,
  DocumentCollectionsResource,
  DocumentCollectionsResourceForCreate,
  DocumentCollectionsResourceForEdit,
} from 'src/common/types';
import AppModel from '../App';
import axios from 'src/utils/axios';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import { FormModel } from '../Form';
import printMessage from 'src/views/errors/MessageError';

export default class DocCollectionsModel extends AppModel 
{
  private static _instance: DocCollectionsModel;
  readonly CollectionCode = new FormModel('collectionCode','Code',45);
  readonly Titre = new FormModel('title','Titre', 255);
  readonly DescriptionFr = new FormModel('descriptionFr','Description (Français)');
  readonly DescriptionEn = new FormModel('descriptionEn','Description (English)');
  readonly Active = new FormModel('active','Actif');
  readonly DocumentType = new FormModel('documentType','Type de contenu', 256);
  readonly DocumentTypeId = new FormModel('documentTypeId',"Type de contenu ID", 50);
  readonly InfoBox = new FormModel('infobox',"Boîte d'information");
  readonly CopyrightZone = new FormModel('copyrightZone',"Droit d'auteur");
  readonly PartnerId = new FormModel('partnerId',"Partenaire");
  readonly AccessCollectionCode = new FormModel('accessCollectionCode',"Classement de contenu", 45);
  readonly DocUrl = new FormModel('docUrl',"Url document", 610);

  constructor(){
    super('/content/document/collections');
    this.initialize();
  }

  private initialize(){
    this._resourceCode = 'DOCUMENT_COLLECTIONS';
    this._headerTitle = 'Liste des collections';
    this._btnAddText = 'Ajouter une collection';
    this.Path.PathName = '/contents/document-collection';

    //Dialog
    this.Dialog.Header = 'Supprimer la collection';
    this.Dialog.BtnText = BtnOk.DELETED;
    this.Dialog.Name = 'la collection';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Collections',
      href: this.Path.Home,
      visible: true
    }
  }

  getHeadCells(status: string){ 
    return [
      {
        id: this.CollectionCode.Name, width: '15%', numeric: false, disablePadding: false, label: this.CollectionCode.Label
      },
      {
        id: this.Titre.Name, width: '20%', numeric: false, disablePadding: false, label: this.Titre.Label
      },
      {
        id: this.DescriptionFr.Name, width: '25%', numeric: false, disablePadding: false, label: this.DescriptionFr.Label
      },
      {
        id: this.DescriptionEn.Name, width: '25%', numeric: false, disablePadding: false, label: this.DescriptionEn.Label
      },
      {
        id: '', width: '5%', numeric: false, disablePadding: false, label: status
      },
      {
        id: '', width: '5%'
      }
    ];
  };

  static getInstance(): DocCollectionsModel {
    if (!DocCollectionsModel._instance) {
      DocCollectionsModel._instance = new DocCollectionsModel();
    }
    return DocCollectionsModel._instance;
  }

  static getInitialValues(values: DocumentCollectionsResource) : DocumentCollectionsResource {
    return {
      id: values.id,
      title: values.title || '',
      collectionCode: values.collectionCode || '',
      descriptionFr: values.descriptionFr || '',
      descriptionEn: values.descriptionEn || '',
      partnerId: values.partnerId,
      active: values.active || false,
      documentType: values.documentType || '',
      documentTypeId: values.documentTypeId || '',
      infobox: values.infobox || '',
      accessCollectionCode: values.accessCollectionCode || '',
      copyrightZone: values.copyrightZone || '',
      docUrl : values.docUrl || ''
    }
  }

  async getCollectionDocuments(): Promise<DocumentCollectionsResource[]> {
    let collections: DocumentCollectionsResource[];
    await axios.get<DocumentCollectionsResource[]>(this.route).then(async (response) => {
      const { status, data } = response;
      if (status === Success) {
        collections = data;
      } 
    },
    async (error) => {
      this.error = error;
      if(!this.skipHandleError){
        await this.handleError(this.error);
      }
    });
    return collections;
  }

  async getCollectionDocument(id: string): Promise<DocumentCollectionsResource> {
    let collection: DocumentCollectionsResource;
    await axios.get<DocumentCollectionsResource>(`${this.route}/${+id}`).then(async (response) => {
      const { status, data } = response;
      if (status === Success) {
        collection = data;
      } 
    },
    async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return collection;
  }

  async insertCollectionDocument(submitData: DocumentCollectionsResourceForCreate): Promise<CreateResponse> {
    let result: CreateResponse;
    console.log('d', submitData)
    await axios.post<DocumentCollectionsResourceForCreate, CreateResponse>(this.route, submitData).then(async (response) => {
      const { status } = response;
      if (status === Created) {
        result = response;
        result.message = 'Collection créé.'
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async updateCollectionDocument(submitData: DocumentCollectionsResourceForEdit): Promise<Error> {
    let result: Error;
    await axios.put<DocumentCollectionsResourceForEdit, Error>(`${this.route}/${submitData.id}`,submitData).then(async (response) => {
      result = response;
      result.message = 'Collection modifiée.';
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async delete(id: number, allowedRedirect: boolean = false): Promise<boolean> {
    let success: boolean = false;
    await axios.delete<any, Error>(`${this.route}/${id}`).then(async (response) => {
      const { status } = response;
      if (status === Success) {
        printMessage({
          status: status, 
          message: 'Collection supprimée.'
        });
        if(allowedRedirect)
          this.redirect(this.Path.Home);
        success = true;
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return success;
  }
}
