import type { DocumentCollectionsResource } from 'src/common/types';
import * as actionTypes from '../ActionTypes';

export const fetchCollections = (collections: DocumentCollectionsResource[], isLoading?: boolean, isEmptyList?: boolean, isAuthorize?: boolean): {
  type: 'FETCH_COLLECTIONS',
  payload: {
    collections: DocumentCollectionsResource[],
    isEmptyList?: boolean,
    isLoading?: boolean,
    isAuthorize?: boolean;
  }
} => ({
  type: actionTypes.FETCH_COLLECTIONS,
  payload: {
    collections,
    isLoading,
    isEmptyList,
    isAuthorize
  }
});

export const fetchCollection = (collection: DocumentCollectionsResource, isLoading?: boolean): {
  type: 'FETCH_COLLECTION',
  payload: {
    collection: DocumentCollectionsResource,
    isLoading?: boolean,
  }
} => ({
  type: actionTypes.FETCH_COLLECTION,
  payload: {
    collection,
    isLoading
  }
});

export const deleteCollection = (id: number): {
  type: 'DELETE_COLLECTION',
  payload: {
    id: number,
  }
} => ({
  type: actionTypes.DELETE_COLLECTION,
  payload: {
    id
  }
});

