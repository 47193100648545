import type { DocumentCollectionsResource } from 'src/common/types';
import * as actionTypes from '../../actions/ActionTypes';

type FetchCollectionsAction = {
    type: 'FETCH_COLLECTIONS';
    payload: {
      collections: DocumentCollectionsResource[];
      isLoading?: boolean;
      isEmptyList?: boolean;
      isAuthorize?: boolean;
    }
};

type FetchCollectionAction = {
  type: 'FETCH_COLLECTION';
  payload: {
    collection: DocumentCollectionsResource;
    isLoading?: boolean;
  }
};

type DeleteCollectionAction = {
  type: 'DELETE_COLLECTION';
  payload: {
    id: number;
  }
};

type Action = FetchCollectionsAction |
              FetchCollectionAction |
              DeleteCollectionAction;

export interface DocCollectionsReturnState {
  collections?: DocumentCollectionsResource[];
  collection?: DocumentCollectionsResource;
  isEmptyList?: boolean;
  isLoading?: boolean;
  isAuthorize?: boolean;
}

interface DocCollectionsState {
  collections: DocumentCollectionsResource[];
  collection: DocumentCollectionsResource;
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
}

const reducer = (state: DocCollectionsState, action: Action): DocCollectionsReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_COLLECTIONS: {
      const { collections, isEmptyList, isLoading, isAuthorize } = action.payload;
      return {
        ...state,
        collections,
        isLoading,
        isEmptyList,
        isAuthorize
      };
    }
    case actionTypes.FETCH_COLLECTION: {
      const { collection, isLoading } = action.payload;
      return {
        ...state,
        collection,
        isLoading
      };
    }
    case actionTypes.DELETE_COLLECTION: {
      return {
        ...state,
        collections: [...state.collections].filter(({id}) => id !== action.payload.id)
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
