import React, { FC, useRef, useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { 
  Autocomplete, 
  Box, 
  CardContent, 
  Grid,  
  TextField 
} from '@mui/material';
import {
  Formik,
  handleChange,
  labelConfig,
  CaijInput,
  CaijButtonSubmit,
  CaijButtonReset,
  button,
  ReperageModel,
  CaijCard,
  AccessModel,
  CaijButton,
} from 'src/common';
import type { 
  Error, 
  CreateResponse, 
  CustomerResource, 
  ReperageResource, 
  ReperageResourceForEdit, 
  ReperageResourceForCreate
} from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import CustomerSearchDialog from './CustomerSearchDialog';
import { Search } from 'react-feather';
import CaijDraftEditor from 'src/components/draftEditor/CaijDraftEditor';

export interface ReperageEditFormProps {
  model: ReperageModel;
  reperage: ReperageResource;
  onSubmit?: (values: ReperageResource) => void;
  customer?: CustomerResource;
  detailPageUrl?: string;
};

async function redirect(model: ReperageModel, response: Error | CreateResponse, detailPageUrl?:string): Promise<void> {
  const createResponse: CreateResponse = response as CreateResponse;
  await model.redirect((createResponse.data?.id ? model.Path.getDetail(createResponse.data.id) : detailPageUrl || model.Path.Home), {
    status: response.status,
    message: response.message
  });
};

const ReperageEditForm: FC<ReperageEditFormProps> = ({ model, reperage, onSubmit, customer, detailPageUrl }) => {
  const access = new AccessModel(model.ResourceCode);
  const [openCustomerSearch, setOpenCustomerSearch] = useState<boolean>(false);
  const setValuesRef = useRef(null);
  const { Name, Infos, ClientLabel, ClientId, Courriel } = model;
  
  const handleSelectCustomer = (customer: CustomerResource) => {
    setValuesRef.current({
      clientLabel: customer.fullname,
      clientId: customer.noCaij,
      courriel: customer.email
    })
  }

  const handleSubmit = async (values: ReperageResource): Promise<void> => {
    if (onSubmit) {
      onSubmit(values);
      return;
    }
    const model = new ReperageModel();
    const submitData = values as ReperageResourceForEdit;
    if (submitData && submitData.id) {
      const response = await model.updateReperage(submitData);
      if (!model.error) {
        await redirect(model, response, detailPageUrl);
      }
    } else {
      const submitData = values as ReperageResourceForCreate;
      const response = await model.insertReperage(submitData);
      if (!model.error) {
        await redirect(model, response);
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={ReperageModel.getInitialValues(reperage, customer)}
        validationSchema={Yup.object().shape({
          name: Name.required(true),
          clientId: ClientId.required(true),
          clientLabel: ClientLabel.required(true),
          courriel: Courriel.required(true)
        })}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting, setValues }) => {
          setValuesRef.current = (newValues: ReperageResource) => setValues({ ...values, ...newValues });
          return (
            <form onSubmit={handleSubmit}>
              <MyCard>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <CaijCard title={"Demande"} sx={{mb:4}} >
                        <CaijInput
                          name={Name.Name}
                          label={Name.Label}
                          value={values.name}
                          required
                          helperText={touched.name && errors.name}
                          error={Boolean(touched.name && errors.name)}
                          InputLabelProps={{ shrink: true, required: true }}
                          inputAttr={{ maxLength: Name.MaxLength, 'aria-label': labelConfig.code, 'data-testid': 'name' }}
                          onHandleBlur={handleBlur}
                          onHandleChange={handleChange}
                          setFieldValue={setFieldValue}
                        />
                        <CaijDraftEditor
                          title={Infos.Label}
                          dataTestId={Infos.Name}
                          value={values.infos}
                          name={Infos.Name}
                          setFieldValue={setFieldValue}
                        />
                      </CaijCard>
                      <CaijCard title={"Membre"} sx={{mb:4}} >
                        <CaijButton startIcon={<Search />} sx={{...button, margin:"20px 0"}} onHandleClick={() => setOpenCustomerSearch(true)}>
                          {values.clientId ? "Modifier le membre" : "Rechercher un membre"}
                        </CaijButton>
                        <CaijInput
                          name={ClientId.Name}
                          label={ClientId.Label}
                          value={values.clientId}
                          required
                          disabled
                          helperText={touched.clientId && errors.clientId}
                          error={Boolean(touched.clientId && errors.clientId)}
                          InputLabelProps={{ shrink: true, required: true }}
                          inputAttr={{ maxLength: ClientId.MaxLength, 'aria-label': labelConfig.code, 'data-testid': 'client_id' }}
                          onHandleBlur={handleBlur}
                          onHandleChange={handleChange}
                          setFieldValue={setFieldValue}
                        />
                        <CaijInput
                          name={ClientLabel.Name}
                          label={ClientLabel.Label}
                          value={values.clientLabel}
                          required
                          disabled
                          helperText={touched.clientLabel && errors.clientLabel}
                          error={Boolean(touched.clientLabel && errors.clientLabel)}
                          InputLabelProps={{ shrink: true, required: true }}
                          inputAttr={{ maxLength: ClientLabel.MaxLength, 'aria-label': labelConfig.code, 'data-testid': 'client_label' }}
                          onHandleBlur={handleBlur}
                          onHandleChange={handleChange}
                          setFieldValue={setFieldValue}
                        />
                        <CaijInput
                          name={Courriel.Name}
                          label={Courriel.Label}
                          value={values.courriel}
                          required
                          disabled
                          helperText={touched.courriel && errors.courriel}
                          error={Boolean(touched.courriel && errors.courriel)}
                          InputLabelProps={{ shrink: true, required: true }}
                          inputAttr={{ maxLength: Courriel.MaxLength, 'aria-label': labelConfig.code, 'data-testid': 'courriel' }}
                          onHandleBlur={handleBlur}
                          onHandleChange={handleChange}
                          setFieldValue={setFieldValue}
                        />
                      </CaijCard>
                      <CaijCard title={"Type de repérages"} sx={{mb:4}} >
                        <Autocomplete
                          multiple
                          value={values.types}
                          id="types"
                          options={[
                            "Plumitif",
                            "Repérage électronique",
                            "Repérage papier",
                            "Aide à la recherche",
                            "Edilex"
                          ]}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={model.Types}
                              placeholder="Choisir un ou plusieurs types..."
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                          onChange={(event, typeValues, reason) => setValues({ ...values, types: typeValues })}
                        />
                      </CaijCard>
                    </Grid>
                  </Grid>
                  <Box mt={2}>
                    <CaijButtonSubmit disabled={(!access.Consultation && !access.Edition) || isSubmitting} sx={button} />
                    <CaijButtonReset disabled={isSubmitting} pathName={reperage.id ? model.Path.getDetail(reperage.id) : model.Path.Home} />
                  </Box>
                </CardContent>
              </MyCard>
            </form>
          )
        }}
      </Formik>
      <CustomerSearchDialog
        onClose={() => setOpenCustomerSearch(false)}
        onSelect={handleSelectCustomer}
        open={openCustomerSearch}
        model={model}
      />
    </>
  );
};

ReperageEditForm.propTypes = {
  reperage: PropTypes.object.isRequired,
  model: PropTypes.instanceOf(ReperageModel).isRequired,
  onSubmit: PropTypes.func,
  customer: PropTypes.object,
  detailPageUrl: PropTypes.string
};

ReperageEditForm.defaultProps = {
  onSubmit: null
};

export default ReperageEditForm;
