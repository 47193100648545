import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import type { DocumentCollectionsResource } from 'src/common/types';
import DocCollectionsFrInfo from './DocCollectionsFrInfo';
import DocCollectionsEnInfo from './DocCollectionsEnInfo';
import { DocCollectionsModel } from 'src/common';

interface DetailsProps {
  model: DocCollectionsModel;
  collection: DocumentCollectionsResource
}

const Details: FC<DetailsProps> = ({model,collection}) => (
  <Grid
    container
    spacing={3}
  >
    <Grid
      item
      lg={6}
      md={6}
      xl={6}
      xs={12}
    >
      <DocCollectionsFrInfo model={model} collection={collection} />
    </Grid>
    <Grid
      item
      lg={6}
      md={6}
      xl={6}
      xs={12}
    >
      <DocCollectionsEnInfo model={model} collection={collection} />
    </Grid>
  </Grid>
);

Details.propTypes = {
  collection: PropTypes.object.isRequired
};

export default Details;
