import React, { useEffect, useCallback, useReducer } from 'react';
import type { FC } from 'react';
import {
  Box,
  Container
} from '@mui/material';
import {
  useLocation,
  _docCollections,
  DocCollectionsModel,
  AccessModel,
  root,
  fetchCollections,
  deleteCollection
} from 'src/common';
import Page from 'src/components/Page';
import Results from './Results';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  collections: [],
  isLoading: true,
  isEmptyList: true
};

const DocCollectionsListView: FC = () => {
  const model = DocCollectionsModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_docCollections, initialState);
  
  const getCollectionDocuments = useCallback(async () : Promise<void> => {
    if(access.canRead()){
      const model = new DocCollectionsModel();
      model.PathName = location.pathname;
      const collections = await model.getCollectionDocuments();
      if (!model.error) {
        dispatch(fetchCollections(collections, false, !Array.isArray(collections) || collections.length === 0));
      }
    }
  }, [location]);

  useEffect(() => {
    (async () => await getCollectionDocuments())();
  },[]);
 
  const handelDelete: (id: number) => Promise<void> = async (id) => {
    if(id && access.canDelete()){
      if(await model.delete(id))
        dispatch(deleteCollection(id));
    }
  };

  const { collections, isEmptyList, isLoading } = state;

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
      <GenericHeader model={model} actions={['add']} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            model={model}
            collections={collections}
            deleteCollection={handelDelete}
            isEmptyList={isEmptyList}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default DocCollectionsListView;
