import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { TableBody } from '@mui/material';
import {
  labelConfig,
  EmptyList,
  getOpacity,
  DocCollectionsModel
} from 'src/common';
import EnhancedTableHead from 'src/components/EnhancedTableHead';
import type { DocumentCollectionsResource } from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import CaijDialogs from 'src/components/dialog';
import { CaijTableCell, CaijTableCellActive } from 'src/components/table/CaijTableCell';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';
import { CaijTableRowClickable } from 'src/components/table/CaijTableRowClickable';
import useSort from 'src/functions/hooks/sort';

export interface ResultsProps {
  model: DocCollectionsModel;
  collections: DocumentCollectionsResource[];
  deleteCollection: (id: number) => Promise<void>;
  isEmptyList: boolean;
}

const Results: FC<ResultsProps> = ({
  model,
  collections,
  deleteCollection,
  isEmptyList
}) => {
  const path = model.Path;
  const [page, setPage] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState([]);
  const { order, orderBy, setOrderBy, sort, handleRequestSort } = useSort();
  
  useEffect(() => setOrderBy('collectionCode'),[]);
  
  const renderTableCell = (row: DocumentCollectionsResource) => {
    const opacity = getOpacity(row.active);
    return (
      <>
        <CaijTableCell opacity={opacity}>{row.collectionCode}</CaijTableCell>
        <CaijTableCell opacity={opacity}>{row.title}</CaijTableCell>
        <CaijTableCell opacity={opacity} whiteSpace='pre-wrap'>{row.descriptionFr}</CaijTableCell>
        <CaijTableCell opacity={opacity} whiteSpace='pre-wrap'>{row.descriptionEn}</CaijTableCell>
        <CaijTableCellActive active={row.active} />
        <CaijTableCellAction 
          pageEditUrl={path.getEdit(row.id)}
          resourceCode={model.ResourceCode}
          handleToggle={() => DocCollectionsModel.handleToggle(row.id, selectedRow, setSelectedRow)}
        >
          <CaijDialogs
            dialog={model.Dialog}
            isOpen={selectedRow.indexOf(row.id) !== -1}
            onSubmit={async () => {
              await deleteCollection(row.id);
              setSelectedRow([]);
            }}
            setSelectedRow={setSelectedRow}
          />
        </CaijTableCellAction>
      </>
    )
  }

  return isEmptyList ? <EmptyList /> : (
    <MyCard>
      <CaijTable>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={model.getHeadCells(labelConfig.status)}
        />
        <TableBody>
          { sort<DocumentCollectionsResource>(collections, page).map((row: DocumentCollectionsResource) => (
                <CaijTableRowClickable key={row.id} path={path.getDetail(row.id)}>
                  {renderTableCell(row)}
                </CaijTableRowClickable>
              ) 
            )
          }
        </TableBody>
      </CaijTable>
      <CaijTablePagination
        showTablePaginationLight 
        len={collections.length}
        page={page}
        setPage={(l) => setPage(l)}
      />
    </MyCard>
  );
};

Results.propTypes = {
  collections: PropTypes.array.isRequired,
  deleteCollection: PropTypes.func.isRequired,
  isEmptyList: PropTypes.bool.isRequired
};

export default Results;
